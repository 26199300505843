<template>
  <h1 :class="$style.root">
    <slot />
  </h1>
</template>

<style module>
  .root {
    font-size: clamp(1.8rem, 3vw, 3.8rem);
    margin: 2vh 0;
    color: var(--color-accent);
    font-weight: lighter;
  }
</style>
